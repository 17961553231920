import { BsTwitter, BsGithub, BsLinkedin,BsGlobe2 } from 'react-icons/bs'
import { SiLeetcode } from 'react-icons/si'

export default function SocialMedia() {
  return (
    <>
      <div className="app__social">
        <div
          onClick={() => {
            // open in new tab
            window.open('https://blog.mrayhanfadil.my.id/', '_blank')
          }}
        >
          <BsGlobe2 />
        </div>
        <div
          onClick={() => {
            // open in new tab
            window.open('https://twitter.com/rayhanfadil10', '_blank')
          }}
        >
          <BsTwitter />
        </div>
        <div
          onClick={() => {
            // open in new tab
            window.open('https://github.com/Fadil3', '_blank')
          }}
        >
          <BsGithub />
        </div>
        <div
          onClick={() => {
            window.open('https://www.linkedin.com/in/mrayhanfadil/', '_blank')
          }}
        >
          <BsLinkedin />
        </div>
        <div
          onClick={() => {
            window.open('https://leetcode.com/Fadil3/', '_blank')
          }}
        >
          <SiLeetcode />
        </div>
      </div>
    </>
  )
}
