import {
  About,
  Footer,
  Header,
  Skills,
  Testimonials,
  Portofolio,
} from './container'
import { Navbar } from './components'
import './App.scss'

export default function App() {
  return (
    <div className="app">
      <Navbar />
      <Header />
      {/* <About /> */}
      <Portofolio />
      <Skills />
      {/* <Testimonials /> */}
      <Footer />
    </div>
  )
}
